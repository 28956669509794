import "./App.css";
import { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";

import gsap from "gsap";

import Nav from "./Components/Nav.jsx";
import Landing from "./Components/Landing.jsx";
import About from "./Components/About.jsx";
import Contacts from "./Components/Contacts.jsx";

import WhtLogo from "./assets/images/SCLogoROUNDED.svg";
import City1 from "./assets/video/FullScorpionVideo.mp4";

const App = () => {
  const [currentPage, setCurrentPage] = useState("/");

  useEffect(() => {
    let smx = gsap.context(() => {
      if (currentPage === "/") {
        gsap.fromTo(
          ".nav",
          {
            y: 5,
            opacity: 0,
            ease: "power3.inOut",
            duration: 1.8,
          },

          { y: 0, opacity: 1, delay: 1, stagger: 0.2 }
        );
      }

      return () => smx.revert();
    });
  }, [currentPage]);

  return (
    <div className="App">
      <header>
        <div className="logo">
          <Link
            to="/"
            onClick={() => {
              setCurrentPage("/");
            }}
          >
            <img src={WhtLogo} alt="Scorpion Finance Logo" />
          </Link>
        </div>
      </header>
      <main className="video-section">
        <div className="video-container">
          <video src={City1} autoPlay loop muted playsInline />
        </div>
      </main>
      <Nav currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <Routes>
        <Route index path="/" element={<Landing currentPage={currentPage} />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contacts />} />
      </Routes>
    </div>
  );
};

export default App;

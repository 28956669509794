import React from "react";

const About = () => {
  return (
    <div className="About">
      <main className="video-section">
        <div className="blurb-container">
          <h3 className="blurb">
            <span>
              <strong>Scorpion Capital</strong> provides tailored solutions for
              both corporate and private clients. Operating at the highest level
              our experienced team of financial experts offer a wide range of
              services helping our clients access the capital they require.
            </span>
          </h3>
          <h3 className="blurb">
            <span>
              We offer debt and equity financing as well as high geared funding
              for development projects with no ceiling. With a commitment to
              exceptional customer service, integrity and professionalism, we
              have built a reputation as a trusted financial partner for our
              clients.
            </span>
          </h3>
        </div>
      </main>
    </div>
  );
};

export default About;

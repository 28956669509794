import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

const Nav = ({ currentPage, setCurrentPage }) => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <nav className="nav">
      <div className="nav-items primary">
        <div className="menu-toggle" onClick={() => setNavOpen(!navOpen)}>
          <div className={navOpen ? "hamBox hamBoxOpen" : "hamBox"}>
            <span className="lineTop" id={navOpen ? "topSpin" : null}></span>
            <span
              className="lineBottom"
              id={navOpen ? "bottomSpin" : null}
            ></span>
          </div>
        </div>
      </div>

      <div
        className="nav-overlay"
        style={{
          top: navOpen ? "0" : "-100vh",
          transitionDelay: navOpen ? "0s" : "0s",
        }}
      >
        <ul className="nav-links">
          <li className="nav-item">
            <div className="nav-item-wrapper">
              <Link
                to="/"
                onClick={() => {
                  setNavOpen(!navOpen);
                  setCurrentPage("/");
                }}
                style={{
                  top: navOpen ? "0" : "120px",
                  transitionDelay: navOpen ? "0.8s" : "0s",
                }}
              >
                HOME
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-item-wrapper">
              <Link
                to="/about"
                onClick={() => {
                  setNavOpen(!navOpen);
                  setCurrentPage("about");
                }}
                style={{
                  top: navOpen ? "0" : "120px",
                  transitionDelay: navOpen ? "0.9s" : "0s",
                }}
              >
                ABOUT
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-item-wrapper">
              <Link
                to="/contact"
                onClick={() => {
                  setNavOpen(!navOpen);
                  setCurrentPage("contacts");
                }}
                style={{
                  top: navOpen ? "0" : "120px",
                  transitionDelay: navOpen ? "1s" : "0s",
                }}
              >
                CONTACT
              </Link>
            </div>
          </li>
        </ul>
        <div className="nav-footer">
          <div
            className="location"
            style={{
              bottom: navOpen ? "0" : "-20px",
              opacity: navOpen ? "1" : "0",
              transitionDelay: navOpen ? "1.2s" : "0",
            }}
          >
            <span>Manchester, UK</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;

import React from "react";
import { useEffect } from "react";
import gsap from "gsap";

const Landing = ({ currentPage }) => {
  useEffect(() => {
    let smx = gsap.context(() => {
      gsap.fromTo(
        ".nav",
        {
          y: 5,
          opacity: 0,
          ease: "power3.inOut",
          duration: 1.8,
        },

        { y: 0, opacity: 1, delay: 1, stagger: 0.2 }
      );

      //block open
      gsap.to(".block-left", {
        opacity: 0,
        ease: "power3.inOut",

        duration: 1.4,
      });

      gsap.to(".block-right", {
        opacity: 0,
        ease: "power3.inOut",

        duration: 1.4,
      });

      return () => smx.revert();
    });
  }, [currentPage]);
  return (
    <div className="landing">
      <div className="blocks">
        <div className="block block-left"></div>
        <div className="block block-right"></div>
      </div>
    </div>
  );
};

export default Landing;

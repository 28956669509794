import React from "react";

const Contacts = () => {
  return (
    <div>
      <main className="video-section">
        <div className="contact-details-container">
          <div className="contact-details">
            <div className="details-wrapper">
              <h3 className="address detail">
                Scorpion Capital <br />
                Capital House <br />
                Droylsden <br />
                Manchester <br />
                M43 6PW
                <br />
                United Kingdom
              </h3>
            </div>
            <div className="details-wrapper">
              <h3 className="tel-no detail">
                Tel:{" "}
                <a className="contact-links" href="tel:08001125007">
                  0800 112 5007
                </a>
              </h3>
            </div>
            <div className="details-wrapper">
              <h3 className="email detail">
                Email:{" "}
                <a
                  className="contact-links"
                  href="mailto:letstalk@scorpioncapital.co.uk"
                >
                  letstalk@scorpioncapital.co.uk
                </a>
              </h3>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Contacts;
